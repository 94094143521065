import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "@lngProvider";

import "@assets/vendors/style";
import "@styles/wieldy.less";
import { history } from "./common/appRedux/store";
import Landing from "./pages/Landing";
import Error from "./pages/Error";
import TermOfUse from "./pages/Landing/TermOfUse";
import Contact from "./pages/Landing/Contact";
import RequestDemo from "./pages/Landing/RequestDemo";

const NextApp = ({ store }) => {
  const { settings } = store.getState();
  const { locale } = settings;
  const currentAppLocale = AppLocale[locale.locale];
  return (
    <Provider store={store}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" exact component={Landing} />
              <Route path="/term-of-use" exact component={TermOfUse} />
              <Route path="/contact-us" exact component={Contact} />
              <Route path="/request-demo" exact component={RequestDemo} />
              <Route component={Error} />
            </Switch>
          </ConnectedRouter>
        </IntlProvider>
      </ConfigProvider>
    </Provider>
  );
};

export default NextApp;
