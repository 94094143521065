import {Card, Col, Image, Row} from "antd";
import image from "@assets/images/cloudsuite_app.svg";
import akaLab from "@assets/images/icons/cloudlab.svg";
import akaOps from "@assets/images/icons/cloudops.svg";
import akaSafe from "@assets/images/icons/cloudsafe.svg";
import akaKube from "@assets/images/icons/cloudnative.svg";
import akaExp from "@assets/images/icons/cloudexpense.svg";
import akaIdentify from "@assets/images/icons/cloudalert.svg";

import React from "react";
import {useTranslation} from "react-i18next";

const {Meta} = Card;

const values = [
    {
        index: 1,
        icon: akaLab,
        title: "STARTING_POINT_CLOUD_JOURNEY",
        message: "STARTING_POINT_CLOUD_JOURNEY_MESSAGE",
        color: "#125ECF",
        url: process.env.REACT_APP_CLOUD_OPS,

    },
    {
        index: 2,
        icon: akaOps,
        title: "CLOUD_OPERATION_MONITORING",
        message: "CLOUD_OPERATION_MONITORING_MESSAGE",
        color: "#F5841F",
        url: process.env.REACT_APP_CLOUD_OPS,
    },
    {
        index: 3,
        icon: akaSafe,
        title: "CLOUD_SECURITY_COMPLIANCE_DEVSECOPS",
        message: "CLOUD_SECURITY_COMPLIANCE_DEVSECOPS_MESSAGE",
        color: "#1eb14b",
        url: process.env.REACT_APP_CLOUD_SAFE,
    },
     {
        index: 5,
        icon: akaExp,
        title: "CLOUD_COST_GOVERNANCE_MANAGEMENT",
        message: "CLOUD_COST_GOVERNANCE_MANAGEMENT_MESSAGE",
        color: "#69bf6f",
        url: process.env.REACT_APP_CLOUD_EXPENSE,
    },
    {
        index: 6,
        icon: akaIdentify,
        title: "CLOUD_IDENTIFY_MANAGEMENT",
        message: "CLOUD_ALERT_MESSAGE",
        color: "#2281e7",
        url: process.env.REACT_APP_CLOUD_ALERT,
    }
];

const Highlights = () => {
    const {t} = useTranslation();

    return (
        <Row gutter={24} justify="center" className="image-background">
            <div className="highlights">
                <div className="item-title">
                    <h1 className="gx-mb-2">{t("CLOUD_SUITE_HIGHLIGHTS")}</h1>
                    <p className="gx-mb-5">{t("CLOUD_PROFESSIONAL_CLOUDFICATION")}</p>
                </div>
                <div className="highlights-content">
                    <Row className="main-content" gutter={32} justify="center">
                        {values.map((item) => (
                            <Col key={item.index} xs={24} md={12} lg={8} sm={24} xl={8} xxl={8}>
                                <div className="item-container">
                                    <Card
                                        className="card-service"
                                        hoverable
                                        cover={
                                            <a href={item.url} target="_blank">
                                                <Image className="icons" preview={false} src={item.icon}/>
                                            </a>
                                        }
                                        actions={[
                                            <a href={item.url} target="_blank" style={{color: item.color}} className="highligh-menu-item">{t("VIEW_MORE")} &gt;</a>
                                        ]}
                                    >
                                        <Meta description={t(item.message)} style={{minHeight: "50px"}}/>
                                    </Card>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </Row>
    );
};

export default Highlights;
