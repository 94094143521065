import React from "react";
import { connect, useSelector } from "react-redux";
import "@styles/landing.less";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "./Slider";
import CloudSuite from "./CloudSuite";
import Values from "./Values";
import Highlights from "./Highlights";
import CloudJourney from "./CloudJourney";
import Accelerator from "./Accelerator";
import DownloadPdf from "./DownloadPdf";
import Customers from "./Customers";
import { useTranslation } from "react-i18next";

document.body.classList.add("full-scroll");


const LandingPage = () => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <div className="landing-layout">
      <Header user={user} />
      <Slider />
      <CloudSuite />
      <Values />
      <Highlights />
      <CloudJourney />
      <Accelerator />
      <DownloadPdf
        title={`FPT CloudSuite - ${t("CLOUD_JOURNEY")}`}
        subTitle={t("FULL_CLOUD_JOURNEY_SERVICES")}
        fileName="Leaflet-Cloudsuite.pdf"
        btnText={t("FULL_CLOUD_JOURNEY_SERVICES_INTRODUCTION")}
      />
      <Customers />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.common,
});

export default connect(mapStateToProps, null)(LandingPage);
